import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ComponentModule } from '@components/component.module';


import { VerifyUserComponent } from './verify-user.component';

import { VerifyUserRoutingModule } from './verify-user-routing.module';

import { SharedModule } from '@/shared/shared.module';

@NgModule({
    declarations: [VerifyUserComponent],
    imports: [CommonModule, SharedModule, VerifyUserRoutingModule, ComponentModule],
})
export class VerifyUserModule { }
