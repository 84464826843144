<app-header [fullMenu]="false"></app-header>

<div class="container bg-light position-relative verify-user">
    <div *ngIf="loaded; else elseBlock" class="row p-4 justify-content-center">
        <div *ngIf="loadedError; else successBlock">
            <div class="row">
                <div class="col align-self-center text-center">
                    <p class="badge-text-danger" style="font-size: 40px">
                        {{ 'web.verification_failed' | translate }}
                        <br />
                        <i class="material-icons"> error </i>
                    </p>
                    <p class="text-secondary">{{ 'web.verification_failed_text' | translate }}</p>
                    <p>
                        <a [routerLink]="['/']" class="link">{{ 'web.go_to_homepage' | translate }}</a>
                    </p>
                </div>
            </div>
        </div>
        <ng-template #successBlock>
            <div class="row">
                <div class="col align-self-center text-center">
                    <p class="badge-text-draw" style="font-size: 20px">
                        {{ 'web.verification_success' | translate }}
                        <br />
                        <i class="material-icons"> check_circle_outline </i>
                    </p>
                    <p class="text-secondary">{{ 'web.verification_success_text' | translate }}</p>
                    <p>
                        <a [routerLink]="['/page/account/dashboard']" color="primary" class="link">{{
                            'web.go_to_account' | translate
                        }}</a>
                    </p>
                </div>
            </div>
        </ng-template>
    </div>
    <ng-template #elseBlock>
        <div class="row p-4 justify-content-center">
            <div class="row">
                <div class="col align-self-center text-center">
                    <p class="text-info" style="font-size: 30px">
                        {{ 'web.verification_in_progress' | translate }}
                        <br />
                        <i class="material-icons"> access_time </i>
                    </p>
                    <p class="text-secondary">{{ 'web.verification_in_progress_text' | translate }}</p>
                    <p>
                        <a [routerLink]="['/']" class="link">{{ 'web.go_to_homepage' | translate }}</a>
                    </p>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<app-footer></app-footer>
