import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InfoService } from 'src/app/services/info.service';
import { UserService } from 'src/app/services/user.service';
import { TranslationService } from 'src/app/services/translation.service';
import { LangService } from 'src/app/services/lang.service';

import { BaseComponent } from '../base.component';

import { WindowRefService } from '@/shared/window-ref';

@Component({
    selector: 'app-verify-user',
    templateUrl: './verify-user.component.html',
})
export class VerifyUserComponent extends BaseComponent
    implements OnInit {
    private code: string;

    public constructor(
        protected router: ActivatedRoute,
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected lang: LangService,
        protected route: Router,
        protected translate: TranslateService,
        protected info: InfoService,
        protected user: UserService,
        private win: WindowRefService,
    ) {
        super(page, title, meta, route, info);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        TranslationService.isLoaded = false;

        this.lang.getLang().then((): void => this.createTitle());

        this.start();
        this.router.paramMap.subscribe((param): void => {
            this.code = param.get('code')!;
            this.user.verify(this.code).subscribe(
                (resp): void => {
                    if (resp.status) {
                        this.win.nativeWindow.dataLayer.push({
                            event: 'verification_complete',

                        });

                        this.user.getStatus().then(
                            (): void => this.finished(),
                            (): void => {
                                this.error();
                            },
                        );
                    } else {
                        this.error(true);
                    }
                },
                (): void => {
                    this.error(true);
                },

            );
        });
    }


    /**
         * Create page title and meta
         * @return Promise<any>
         */
    private createTitle(): void {
        this.translate.get('web.title_verify_user').subscribe((): void => {
            this.setTitle(
                {
                    title: this.translate.instant('web.title_verify_user'),
                    description: this.translate.instant(
                        'web.description_verify_user',
                    ),
                },
                true,
            );
        });
    }
}
